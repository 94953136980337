import url from 'url';
import classNames from 'clsx';
import get from 'lodash/get';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RecentSearchesContext from 'src/common/contexts/RecentSearchesContext';
import { selectIsDiscord, selectIsMobile } from 'src/common/selectors/app';
import { selectIsUnifiedEventsContentReportingEnabled } from 'src/common/selectors/config';
import { selectIsNowPlayingDialogOpen } from 'src/common/selectors/dialog';
import { getSelectProfile } from 'src/common/selectors/profiles';
import { selectBreadcrumbId } from 'src/common/selectors/reporting';
import { brazeLogContentCardClick } from 'src/common/utils/braze/brazeHelper';
import { doesContainerSupportContentReporting } from 'src/common/utils/guideItem/doesContainerSupportContentReporting';
import getGuideItemPathname from 'src/common/utils/guideItem/getGuideItemPathname';
import { closeNowPlayingDialog } from '../../../actions/dialog';
import { logContentCardClick } from '../../../actions/logging';
import {
  reportContentClickedEvent,
  setBreadcrumbId,
} from '../../../actions/reporting';
import isPlayable from '../../../utils/guideItem/isPlayable';
import { SponsoredRollupContext } from '../../ads/SponsoredRollupProvider';
import css from './guide-item.module.scss';

/**
 * TODO:
 * Eventually, this should reference GuideItemLinkWithLogger, so we can have more universal
 * logging of inappropriate links.When switching to using GuideItemLinkWithLogger, we can
 * imagine the `guideItemLink` prop will be something like the following:
 *
 *  guideItemLink = (logger) => {
 *    const guideItemUrl = getGuideItemPathnameWithLogger(guideItem, parentGuideItem)(logger);
 *    if (guideItemUrl) {
 *      return {
 *        ...url.parse(guideItemUrl),
 *        state: {
 *          itemToken: get(guideItem, 'context.token')
 *        },
 *      };
 *    }
 *  return guideItemUrl;
 * }
 */

function updateLocation(to, updater) {
  return updater ? updater(to) : to;
}

function getLocation(guideItem, guideItemUrl, augmentGuideItemLink) {
  const location = {
    ...url.parse(guideItemUrl),
    state: { itemToken: guideItem?.context?.token },
  };

  // augmentGuideItemLink is injected by the SponsoredRollupProvider in order
  // to add the categoryId to the location state. See SponsoredRollupProvider
  // for more details.
  return updateLocation(location, augmentGuideItemLink);
}

function GuideItemLink({
  id,
  guideItem,
  children,
  style,
  className,
  onClick,
  dataTestId,
  parentGuideItem,
  isMobile,
  actions,
  isNowPlayingOpen,
}) {
  const onSearchClick = useContext(RecentSearchesContext);
  const guideItemUrl = getGuideItemPathname(guideItem, parentGuideItem);
  const augmentGuideItemLink = useContext(SponsoredRollupContext);
  const isUnifiedEventsContentReportingEnabled = useSelector(
    selectIsUnifiedEventsContentReportingEnabled,
  );
  const breadcrumbId = useSelector(selectBreadcrumbId);
  const isDiscord = useSelector(selectIsDiscord);

  const handleClick = (event, to = null) => {
    if (isUnifiedEventsContentReportingEnabled) {
      if (!breadcrumbId || (isMobile && isNowPlayingOpen)) {
        // We set the breadcrumbId before 'onClick' is called, so the events which called right after(eg. USER_PLAY_CLICKED, LISTEN_SESSION_STARTED) also pass the breadcrumbId.
        actions.setBreadcrumbId();
      }

      if (
        doesContainerSupportContentReporting(
          guideItem.reporting?.container?.type,
        )
      ) {
        actions.reportContentClickedEvent(guideItem);
      }
    }

    onClick?.(to, event);
    onSearchClick();

    if (guideItem.brazeCardId) {
      brazeLogContentCardClick(guideItem, actions);
    }

    if (isMobile && isNowPlayingOpen && !isPlayable(guideItem)) {
      actions.closeNowPlayingDialog();
    }
  };

  if (isString(guideItemUrl)) {
    const location = getLocation(guideItem, guideItemUrl, augmentGuideItemLink);

    return isDiscord && onClick ? (
      <div
        data-testid={dataTestId}
        style={style}
        className={className}
        onClick={(event) => handleClick(event, location)}
      >
        {children}
      </div>
    ) : (
      <Link
        data-testid={dataTestId}
        to={location}
        style={style}
        className={classNames([className, css.guideItemLink])}
        id={id}
        onClick={(event) => handleClick(event, location)}
      >
        {children}
      </Link>
    );
  }

  return (
    <div
      data-testid={dataTestId}
      style={style}
      className={className}
      onClick={(event) => handleClick(event)}
    >
      {children}
    </div>
  );
}

GuideItemLink.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  guideItem: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  parentGuideItem: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isNowPlayingOpen: PropTypes.bool.isRequired,
};

GuideItemLink.defaultProps = {
  isPlayable: false,
};

// Need to add the parentGuideItem to the GuideItemLink props so that getGuideItemPathname
// has access to the additional SEO destination info that it needs to properly build SEO urls.
function mapStateToProps(state, ownProps) {
  const parentGuideId = get(
    ownProps,
    'guideItem.properties.parentProgram.destinationInfo.id',
  );
  return {
    parentGuideItem: getSelectProfile(parentGuideId)(state),
    isMobile: selectIsMobile(state),
    isNowPlayingOpen: selectIsNowPlayingDialogOpen(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeNowPlayingDialog,
        logContentCardClick,
        reportContentClickedEvent,
        setBreadcrumbId,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideItemLink);
