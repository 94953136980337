import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { WEB_TUNER_PRE_TUNE_ENABLED } from 'src/common/constants/experiments/player';
import { CIRCLE } from 'src/common/constants/tile';
import connectWithPlayer from 'src/common/decorators/connectWithPlayer';
import cssVariables from 'src/common/styles/variables';
import { mintSingleton } from '../../../../client/mint';
import { tunerSingleton } from '../../../../client/tuner';
import { closeNowPlayingDialog } from '../../../actions/dialog';
import { toggleProfileFavorite } from '../../../actions/favorites';
import { MAP_VIEW } from '../../../constants/paths';
import withContentCardTracking from '../../../decorators/containerItems/withContentCardTracking';
import withNowPlayingContext from '../../../decorators/withNowPlayingContext';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectDiscordState, selectIsDiscord } from '../../../selectors/app';
import { selectExperiment } from '../../../selectors/config';
import { selectIsUserSubscribed } from '../../../selectors/me';
import { selectTunedGuideId } from '../../../selectors/player';
import getTunedGuideId from '../../../utils/guideItem/getTunedGuideId';
import hasNoBehavior from '../../../utils/guideItem/hasNoBehavior';
import isLocked from '../../../utils/guideItem/isLocked';
import isPlayable from '../../../utils/guideItem/isPlayable';
import isSelectable from '../../../utils/guideItem/isSelectable';
import { behaviors } from '../../../utils/guideItemTypes';
import { DiscordGeoRestrictionsTileAreaTooltip } from '../../discord/DiscordGeoRestrictionsTooltip';
import GuideItemLink from './GuideItemLink';
import LockedTile from './LockedTile';
import PlayTile from './PlayTile';
import SelectableTile from './SelectableTile';
import css from './play-button.module.scss';

// exported for testing
export const styles = {
  display: 'block',
  backgroundColor: cssVariables['--white'],
  overflow: 'hidden',
  borderRadius: '15px',
  cursor: 'pointer',
  zIndex: '1',
  height: '100%',
  width: '100%',
  boxShadow: '0px 3px 4px 1px rgb(28 31 58 / 12%)',
};

export const discordStyles = {
  backgroundColor: 'rgb(0 0 0 / 0%)',
};

export const noAnchorOverrideStyle = {
  cursor: 'default',
};

// exported for testing
export const stylesOverridesMap = {
  [CIRCLE]: {
    borderRadius: '50%',
  },
};

function isConnectingToGuideId(guideItem) {
  return (
    tunerSingleton.instance?.state?.name === 'connecting' &&
    tunerSingleton.instance?.tune?.guideId === getTunedGuideId(guideItem)
  );
}

export class WithGuideItemActions extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    guideItem: PropTypes.object.isRequired,
    playerActions: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    syncedPlayerStatus: PropTypes.string.isRequired,
    syncedPlayerAction: PropTypes.func.isRequired,
    isSyncedWithPlayer: PropTypes.bool.isRequired,
    isMediaAdLoaded: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    nowPlayingContext: PropTypes.object.isRequired,
    canScrub: PropTypes.bool,
    style: PropTypes.object,
    playButtonStyles: PropTypes.object,
    className: PropTypes.string,
    iconFontSize: PropTypes.string,
    tileSize: PropTypes.string,
    tileShape: PropTypes.string,
    isNowPlayingOpen: PropTypes.bool,
    isClickHandlerEnabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    disablePreroll: PropTypes.bool,
    trackingRef: PropTypes.object.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isPreTuneEnabled: PropTypes.bool.isRequired,
    tunedGuideId: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  componentDidMount() {
    if (this.props.isPreTuneEnabled) {
      this.handlePretune();
    }
  }

  navigateAndTuneOnPlay = (to) => {
    const { history, guideItem, playerActions } = this.props;
    this.tunePromise = playerActions.tune({ guideItem });
    // tune() should only be called when isPlayable() is true for the guideItem
    // this check was done below when passing in handleGuideItemLinkClick() as the onClick prop

    history.push(to);
  };

  async handlePretune() {
    const { guideItem, isPremium } = this.props;
    const guideId = getTunedGuideId(guideItem);

    if (!guideId) {
      return;
    }

    await mintSingleton.readyPromise;
    await tunerSingleton.readyPromise;

    const preTuneOptions = {
      guideId,
      itemToken: guideItem.context?.token,
    };

    if (!isPremium) {
      preTuneOptions.palNonce = await mintSingleton.instance?.getPalNonce();
    }

    tunerSingleton.instance?.preTune(preTuneOptions);
  }

  async handleClickForDiscord(to) {
    const {
      isNowPlayingOpen,
      history,
      actions,
      guideItem,
      playerActions,
      tunedGuideId,
    } = this.props;
    const { location } = this.context;
    const defaultActionName = guideItem?.behaviors?.default?.actionName;

    await this.tunePromise;

    if (isConnectingToGuideId(guideItem)) {
      return;
    }

    if (location.pathname === MAP_VIEW) {
      this.tunePromise = playerActions.tune({ guideItem });
      return;
    }

    switch (defaultActionName) {
      case behaviors.browse:
      case behaviors.profile:
        if (!tunedGuideId) {
          this.tunePromise = playerActions.tune({ guideItem });
        }

        if (to.pathname === location.pathname && isNowPlayingOpen) {
          actions.closeNowPlayingDialog();
          return;
        }

        history.push(to);
        break;
      case behaviors.play:
        this.tunePromise = playerActions.tune({ guideItem });
        break;
      default:
    }
  }

  handleGuideItemLinkClick = async (to, event) => {
    event.preventDefault();

    const {
      playerActions,
      syncedPlayerAction,
      isSyncedWithPlayer,
      canScrub,
      guideItem,
      isNowPlayingOpen,
      nowPlayingContext,
      isMediaAdLoaded,
      disableNavigation,
      onClick,
      disablePreroll,
      isDiscord,
    } = this.props;

    onClick?.();

    if (isDiscord) {
      this.handleClickForDiscord(to);
      return;
    }

    if (isSyncedWithPlayer && isMediaAdLoaded) {
      return;
    }

    // when in nowplaying and currently playing item is clicked
    // we want to only scroll and NOT control playback or retune
    if (isSyncedWithPlayer && isNowPlayingOpen) {
      nowPlayingContext.scrollToTop();
      return;
    }

    await this.tunePromise;

    if (isConnectingToGuideId(guideItem)) {
      return;
    }

    if (isSyncedWithPlayer) {
      syncedPlayerAction(playerActions, canScrub);
      return;
    }

    if (!disableNavigation && to) {
      this.navigateAndTuneOnPlay(to);
    } else {
      this.tunePromise = playerActions.tune({ guideItem, disablePreroll });
    }

    if (isNowPlayingOpen) {
      nowPlayingContext.scrollToTop();
    }
  };

  renderContent() {
    const {
      guideItem,
      syncedPlayerStatus,
      children,
      canScrub,
      style,
      className,
      isMobile,
      tileSize,
      tileShape,
      isClickHandlerEnabled,
      isSelected,
      isDiscord,
      tunedGuideId,
      discordState,
    } = this.props;

    const stylesOverrides = stylesOverridesMap[tileShape] || {};
    const combinedStyles = {
      ...styles,
      ...stylesOverrides,
      ...style,
      ...(isDiscord && discordStyles),
    };

    /* No anchor */
    if (hasNoBehavior(guideItem)) {
      const noAnchorStyle = { ...combinedStyles, ...noAnchorOverrideStyle };
      return (
        <div className={className} style={noAnchorStyle}>
          {children}
        </div>
      );
    }

    /* Premium Gated Station - it should show the upsell dialog */
    if (isLocked(guideItem)) {
      return (
        <LockedTile
          guideItem={guideItem}
          className={className}
          style={combinedStyles}
          isClickHandlerEnabled={isClickHandlerEnabled}
        >
          {children}
        </LockedTile>
      );
    }

    /* If it's playable then anchor so tune begins on click */
    if (isPlayable(guideItem)) {
      return (
        <GuideItemLink
          dataTestId="guideItemLink"
          onClick={this.handleGuideItemLinkClick}
          guideItem={guideItem}
          className={className}
          style={combinedStyles}
        >
          <PlayTile
            syncedPlayerStatus={syncedPlayerStatus}
            canScrub={canScrub}
            isMobile={isMobile}
            isDiscord={isDiscord}
            tileSize={tileSize}
            tileShape={tileShape}
            guideId={guideItem?.guideId}
            tunedGuideId={tunedGuideId}
            canControlPlayback={discordState.canControlPlayback}
          />
          {isDiscord && (
            <DiscordGeoRestrictionsTileAreaTooltip guideItem={guideItem} />
          )}
          {children}
        </GuideItemLink>
      );
    }

    if (isSelectable(guideItem)) {
      return (
        <SelectableTile
          className={className}
          style={{ ...combinedStyles, borderRadius: '6px' }}
          isSelected={isSelected}
        >
          {children}
        </SelectableTile>
      );
    }

    /* Default anchor to browse or profile */
    return (
      <GuideItemLink
        dataTestId="guideItemLink"
        guideItem={guideItem}
        style={combinedStyles}
        className={className}
      >
        {children}
      </GuideItemLink>
    );
  }

  render() {
    const { playButtonStyles, trackingRef } = this.props;
    return (
      <div
        className={css.playButton}
        style={{ ...playButtonStyles }}
        data-testid="guideItemPlayButtonContainer"
        ref={trackingRef}
      >
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    discordState: selectDiscordState(state),
    isDiscord: selectIsDiscord(state),
    isPremium: selectIsUserSubscribed(state),
    isPreTuneEnabled: selectExperiment(
      state,
      WEB_TUNER_PRE_TUNE_ENABLED,
      false,
    ),
    tunedGuideId: selectTunedGuideId(state),
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { toggleProfileFavorite, closeNowPlayingDialog };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  connectWithPlayer,
  withRouter,
  withNowPlayingContext,
  withContentCardTracking,
)(WithGuideItemActions);
