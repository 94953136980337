import { connect } from 'react-redux';
import NowPlayingContext from '../contexts/NowPlayingContext';
import { selectIsNowPlayingDialogOpen } from '../selectors/dialog';

export default function withNowPlayingContext(MyComponent) {
  const WithNowPlayingContext = (props) => (
    <NowPlayingContext.Consumer>
      {(nowPlayingContext) => (
        <MyComponent {...props} nowPlayingContext={nowPlayingContext} />
      )}
    </NowPlayingContext.Consumer>
  );

  const mapStateToProps = (state) => ({
    isNowPlayingOpen: selectIsNowPlayingDialogOpen(state),
  });

  return connect(mapStateToProps)(WithNowPlayingContext);
}
