import update from 'immutability-helper';
import isPlainObject from 'lodash/isPlainObject';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { MINT_CATEGORY_ID } from '../../constants/ads/location';
import { isCategory } from '../../utils/guideItemTypes';

/**
 * A provider that injects an augmentGuideItemLink function into the
 * context. This function can then be called by GuideItemLink in order
 * to add the guideId to the "to" object if it's a category. This guideId
 * is needed to update the mint categoryId state within the DisplayAdSlot
 * so that SponsoredRollup ad campaigns can be linked between category
 * browser pages and profiles.
 *
 * Ticket: REV-2536
 * Spec: https://tunein.atlassian.net/wiki/spaces/ENG/pages/19029395/Sponsored+Rollup+Technical+Spec
 */
const SponsoredRollupContext = createContext();
SponsoredRollupContext.displayName = 'SponsoredRollupProvider';

const SponsoredRollupProvider = ({ guideId, children }) => {
  const augmentGuideItemLink = (to) => {
    if (!isCategory(guideId) || !isPlainObject(to)) {
      return to;
    }
    return update(to, { state: { $merge: { [MINT_CATEGORY_ID]: guideId } } });
  };

  return (
    <SponsoredRollupContext.Provider value={augmentGuideItemLink}>
      {children}
    </SponsoredRollupContext.Provider>
  );
};

SponsoredRollupProvider.propTypes = {
  children: PropTypes.node.isRequired,
  guideId: PropTypes.string.isRequired,
};

export default SponsoredRollupProvider;
export { SponsoredRollupContext };
