import classNames from 'clsx';
import PropTypes from 'prop-types';
import guideItemCss from './guide-item.module.scss';
import css from './selectable-tile.module.scss';

export default function SelectableTile({
  children,
  style,
  className,
  dataTestId = 'selectableTile',
  isSelected,
}) {
  return (
    <>
      <div
        data-testid={dataTestId}
        className={classNames([
          css.selectableTile,
          className,
          guideItemCss.guideItemLink,
        ])}
        style={style}
      >
        {children}
      </div>
      <div
        className={classNames(css.selectableTileOverlay, {
          [css.selected]: isSelected,
        })}
      />
      <div
        className={classNames(css.checkmark, { [css.visible]: isSelected })}
      />
    </>
  );
}

SelectableTile.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
};
