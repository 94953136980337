import PropTypes from 'prop-types';
import { useState } from 'react';
import useOnMount from 'src/common/hooks/useOnMount';
import { Tooltip } from './Tooltip';

export function WebTooltip({
  open,
  delayDuration = 700,
  side = 'top',
  content,
  // Defaults to the id of the ScrollReset component in AppRoot.js, as document.body is effectively collapsed, so can't be used as a portal container
  portalContainerId = 'appRootScrollReset',
  children,
}) {
  const [portalContainer, setPortalContainer] = useState(null);

  useOnMount(() => {
    setPortalContainer(document.getElementById(portalContainerId));
  });

  if (!portalContainer) {
    return null;
  }

  return (
    <Tooltip
      open={open}
      delayDuration={delayDuration}
      side={side}
      content={content}
      portalContainer={portalContainer}
    >
      {children}
    </Tooltip>
  );
}

WebTooltip.propTypes = {
  open: PropTypes.bool,
  delayDuration: PropTypes.number,
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  content: PropTypes.node.isRequired,
  portalContainerId: PropTypes.string,
  children: PropTypes.node.isRequired,
};
