import PropTypes from 'prop-types';
import { useRef } from 'react';
import EventListener, { withOptions } from 'react-event-listener';
import { doesContainerSupportContentReporting } from 'src/common/utils/guideItem/doesContainerSupportContentReporting';
import useOnMount from '../../hooks/useOnMount';
import useContentCardImpressionReporting from './useContentCardImpressionReporting';

export default function withContentCardTracking(ComponentToDecorate) {
  const WithContentCardTracking = (props) => {
    const { guideItem } = props;

    const componentRef = useRef();

    const { reportIfInView } = useContentCardImpressionReporting(
      guideItem,
      componentRef,
    );

    const isContentReportingEnabled = doesContainerSupportContentReporting(
      guideItem.reporting?.container?.type,
    );

    useOnMount(() => {
      if (isContentReportingEnabled) {
        reportIfInView();
      }
    });

    if (isContentReportingEnabled) {
      return (
        <EventListener
          target="window"
          onScroll={withOptions(reportIfInView, {
            passive: false,
            capture: true,
          })}
        >
          <ComponentToDecorate trackingRef={componentRef} {...props} />
        </EventListener>
      );
    }

    return <ComponentToDecorate {...props} />;
  };

  WithContentCardTracking.propTypes = {
    guideItem: PropTypes.object.isRequired,
  };

  return WithContentCardTracking;
}
