import classNames from 'clsx';
import PropTypes from 'prop-types';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import { CIRCLE } from 'src/common/constants/tile';
import playerStatusToIconMap, {
  playerIconClasses,
} from 'src/common/utils/playerStatusToIconMap';
import { isDiscordMode } from '../../../utils/discord';
import { getCssStyle } from '../../../utils/getCssStyle';
import CircularProgress from '../../shared/CircularProgress';
import PauseCircleSolid from '../../shared/svgIcons/player/PauseCircleSolid';
import PlayCircleSolid from '../../shared/svgIcons/player/PlayCircleSolid';
import StopCircleSolid from '../../shared/svgIcons/player/StopCircleSolid';
import css from './play-button.module.scss';

const svgProps = {
  height: '40',
  width: '40',
  viewBox: '0 0 45 45',
  fill: '#FFFFFF',
  className: css.iconPosition,
  doNotHover: true,
  preserveAspectRatio: 'xMaxYMax',
  ...(isDiscordMode() && {
    fillButtonBackground: getCssStyle(
      '--discord-tile-control-button-background-color',
    ),
  }),
};

const tileSizeSvgPropsMap = {
  Regular: {
    height: '60%',
    width: '60%',
    className: classNames(css.iconPosition, css.regularTileSizeOffset),
  },
};

const tileShapeSvgPropsMap = {
  [CIRCLE]: {
    height: '40%',
    width: '40%',
    className: classNames(css.iconPosition, css.circleTileShapeOffset),
  },
};

const svgIconClassMap = {
  [playerIconClasses.play]: {
    SvgComponent: PlayCircleSolid,
    dataTestId: 'playIcon',
  },
  [playerIconClasses.pause]: {
    SvgComponent: PauseCircleSolid,
    dataTestId: 'pauseIcon',
  },
  [playerIconClasses.stop]: {
    SvgComponent: StopCircleSolid,
    dataTestId: 'stopIcon',
    iconSvgProps: {
      viewBox: '0 0 48 48',
    },
  },
};

const PlayTile = ({
  syncedPlayerStatus,
  canScrub,
  isMobile,
  isDiscord,
  tileSize,
  tileShape,
  tunedGuideId,
  canControlPlayback = true,
}) => {
  const iconClassName = playerStatusToIconMap[syncedPlayerStatus](canScrub);
  const isPlayingOrConnecting =
    syncedPlayerStatus === playerStatuses.playing ||
    syncedPlayerStatus === playerStatuses.connecting;

  const { SvgComponent, dataTestId, iconSvgProps } =
    svgIconClassMap[iconClassName] || {};
  const tileSizePropsOverride = tileSizeSvgPropsMap[tileSize] || {};
  const tileShapePropsOverride = tileShapeSvgPropsMap[tileShape] || {};
  const svgComponentProps = {
    ...svgProps,
    ...iconSvgProps,
    ...tileSizePropsOverride,
    ...tileShapePropsOverride,
    dataTestId,
  };

  const isControllable =
    !!SvgComponent &&
    (isDiscord ? canControlPlayback && !tunedGuideId : canControlPlayback);

  return (
    <div
      data-testid="guideItemPlayOverlay"
      className={classNames(css.overlay, {
        [css.show]: isPlayingOrConnecting,
        [css.hide]: !isPlayingOrConnecting,
        [css.mobile]: isMobile,
      })}
    >
      {syncedPlayerStatus === playerStatuses.connecting && (
        <div className={css.progressWrapper}>
          <CircularProgress color="#fff" thickness={2} size={40} />
        </div>
      )}
      {isControllable && <SvgComponent {...svgComponentProps} />}
    </div>
  );
};

PlayTile.propTypes = {
  syncedPlayerStatus: PropTypes.string,
  canScrub: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isDiscord: PropTypes.bool,
  tileSize: PropTypes.string,
  tileShape: PropTypes.string,
  tunedGuideId: PropTypes.string,
  canControlPlayback: PropTypes.bool,
};

export default PlayTile;
