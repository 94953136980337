import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import PropTypes from 'prop-types';
import css from './tooltip.module.scss';

export function Tooltip({
  open = false,
  delayDuration = 700,
  side = 'top',
  content,
  portalContainer,
  children,
}) {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root open={open}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal container={portalContainer}>
          <TooltipPrimitive.Content side={side} className={css.content}>
            {content}
            <TooltipPrimitive.Arrow className={css.arrow} />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

Tooltip.propTypes = {
  open: PropTypes.bool,
  delayDuration: PropTypes.number,
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  content: PropTypes.node.isRequired,
  portalContainer: PropTypes.object,
  children: PropTypes.node.isRequired,
};
