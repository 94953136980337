import { useContext } from 'react';
import {
  InteractiveTileArea,
  InteractiveTileAreaContext,
} from 'src/common/components/containerItems/shared/InteractiveTileArea';
import { WebTooltip } from 'src/common/components/shared/WebTooltip';
import { InfoIcon } from 'src/common/components/shared/svgIcons/InfoIcon';
import { useDiscordGeorestrictedUsersCountMessage } from 'src/common/hooks/useDiscordGeorestrictedUsersCountMessage';
import css from './discord-georestrictions-tooltip.module.scss';

export function DiscordGeoRestrictionsInfoTooltip({
  georestrictedUsersCountMessage,
}) {
  const isOpen = useContext(InteractiveTileAreaContext);

  return (
    <WebTooltip
      delayDuration={0}
      side="bottom"
      open={isOpen}
      content={
        <div className={css.tooltipContent}>
          {georestrictedUsersCountMessage}
        </div>
      }
    >
      <div className={css.iconBackground}>
        <InfoIcon height="100%" width="100%" />
      </div>
    </WebTooltip>
  );
}

export function DiscordGeoRestrictionsTileAreaTooltip({ guideItem }) {
  const georestrictedUsersCountMessage =
    useDiscordGeorestrictedUsersCountMessage(guideItem);

  if (!georestrictedUsersCountMessage) {
    return null;
  }

  return (
    <InteractiveTileArea>
      <DiscordGeoRestrictionsInfoTooltip
        georestrictedUsersCountMessage={georestrictedUsersCountMessage}
      />
    </InteractiveTileArea>
  );
}
