export function isElementInView(element, requirePartialVisibility = false) {
  if (!element?.getBoundingClientRect) {
    return false;
  }

  const { top, right, bottom, left, height } =
    element.getBoundingClientRect() || {};
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth;
  const customBottom = requirePartialVisibility ? bottom - height / 2 : bottom;

  return (
    top >= 0 &&
    left >= 0 &&
    customBottom <= viewportHeight &&
    right <= viewportWidth
  );
}
