import PropTypes from 'prop-types';
import { createContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsMobile } from 'src/common/selectors/app';
import css from './interactive-tile-area.module.scss';

export const InteractiveTileAreaContext = createContext(false);

/**
 * This component creates a user-interactive area that is intended to overlay a parent container (e.g., a guide item tile).
 * It's currently hard-coded to overlay the bottom right quadrant of a container, but can be enhanced later to make sizing
 * and positioning configurable via props, if needed.
 *
 * On mobile, the interactivity state is configured to automatically change to false after 5 seconds of inactivity.
 *
 * Max height and max width are currently hard-coded as 50%.
 * Default height and width are set to 44px, which is the recommended minimum size for interactive components.
 *
 * @param height
 * @param width
 * @param children
 */
export function InteractiveTileArea({
  height = '44px',
  width = '44px',
  children,
}) {
  const [isInteracting, setIsInteracting] = useState(false);
  const isMobile = useSelector(selectIsMobile);
  const timeoutIdRef = useRef();
  const onHoverStart = (e) => {
    if (isMobile) {
      e.stopPropagation();
    }

    clearTimeout(timeoutIdRef.current);
    setIsInteracting(true);

    if (isMobile) {
      timeoutIdRef.current = setTimeout(() => setIsInteracting(false), 5000);
    }
  };
  const onHoverEnd = (e) => {
    if (isMobile) {
      e.stopPropagation();
    }

    clearTimeout(timeoutIdRef.current);
    setIsInteracting(false);
  };

  return (
    <div
      className={css.container}
      onClick={(e) => e.stopPropagation()}
      onTouchStart={onHoverStart}
      onTouchEnd={onHoverEnd}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      style={{ height, width }}
    >
      <InteractiveTileAreaContext.Provider value={isInteracting}>
        {children}
      </InteractiveTileAreaContext.Provider>
    </div>
  );
}

InteractiveTileArea.propTypes = {
  children: PropTypes.node.isRequired,
};
