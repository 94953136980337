import debounce from 'lodash/debounce';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { brazeLogContentCardImpressions } from 'src/common/utils/braze/brazeHelper';
import {
  logContentCardImpression,
  rememberContentCardWithImpressionReported,
} from '../../actions/logging';
import { reportContentImpressionEvent } from '../../actions/reporting';
import useActions from '../../hooks/useActions';
import { selectIsUnifiedEventsContentReportingEnabled } from '../../selectors/config';
import { selectHasContentCardImpressionReported } from '../../selectors/logging';
import { isElementInView } from './utils';

const TIMEOUT = 100;

export default function useContentCardImpressionReporting(
  guideItem,
  componentRef,
) {
  const hasContentCardImpressionReported = useSelector(
    selectHasContentCardImpressionReported(guideItem.brazeCardId),
  );
  const isUnifiedEventsContentReportingEnabled = useSelector(
    selectIsUnifiedEventsContentReportingEnabled,
  );
  const actions = useActions({
    logContentCardImpression,
    rememberContentCardWithImpressionReported,
    reportContentImpressionEvent,
  });
  const isReportedRef = useRef(false);

  const reportIfInView = useMemo(
    () =>
      debounce(() => {
        if (!isElementInView(componentRef.current, true)) {
          return;
        }

        if (guideItem.brazeCardId && !hasContentCardImpressionReported) {
          actions.logContentCardImpression(guideItem);
          actions.rememberContentCardWithImpressionReported(
            guideItem.brazeCardId,
          );
          brazeLogContentCardImpressions([guideItem.brazeCardId]);
        }

        if (isUnifiedEventsContentReportingEnabled && !isReportedRef.current) {
          actions.reportContentImpressionEvent(guideItem);
          isReportedRef.current = true;
        }
      }, TIMEOUT),
    [
      guideItem,
      hasContentCardImpressionReported,
      actions,
      isUnifiedEventsContentReportingEnabled,
      componentRef,
    ],
  );

  return {
    reportIfInView,
  };
}
